// TODO: fix eslint
/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useSelector } from 'react-redux';
import { layers } from './layers';
import { LayersUtils } from 'utils/layers.utils';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MAP_ID = 'map-container';

const MapboxMap = ({ onMapClick, onMapLoad, onBaseLayerChange }) => {
  const mapState = useRef();
  const baseLayer = useRef();

  const { mapType, terrain3D, attributes } = useSelector((state) => state.map);

  const mapInitialization = ({ onMapClick, onMapLoad }) => {
    mapboxgl.accessToken =
      'pk.eyJ1Ijoic2NobWl0dGZ1bWlhbiIsImEiOiJjbHp0aWFrNngyOW54MnJxcm9jcWlvbXU4In0.PNc1P4k2SiTlLLa_q6XiZg';

    const map = new mapboxgl.Map({
      container: MAP_ID,
      zoom: 1,
      style: {
        glyphs: 'mapbox://fonts/webmaster-openaip/{fontstack}/{range}.pbf',
        version: 8,
        sources: {},
        layers: [],
        sprite:
          'https://api.mapbox.com/styles/v1/webmaster-openaip/ckn740ghl0xv717p1jc6wi59p/f1swxnvf2nax17nl4agef47uc/sprite?access_token=pk.eyJ1Ijoid2VibWFzdGVyLW9wZW5haXAiLCJhIjoiY2x3MDk4ajdmMzRuazJrb2RodGs1M3RiZSJ9.oBnOUp8plNDs9Ef8l8jCeg',
      },
      center: [30, 15],
    });

    map.on('click', (event) => {
      if (onMapClick) onMapClick({ ...event, map });
    });

    map.on('load', async (event) => {
      mapState.current = map;
      changeBaseLayer();

      if (onMapLoad) onMapLoad(event);
    });

    return map;
  };

  const createLayer = async ({ id, tiles, type, url, mapType: googleMapType, layerTypes }) => {
    const map = mapState.current;

    if (type === 'vector') {
      await LayersUtils.addVectorLayer({
        map,
        url,
        source: id,
      });

      return;
    }

    if (type === 'google') {
      await LayersUtils.toggleGoogleLayer({
        map,
        mapType: googleMapType,
        layerTypes,
        source: id,
      });
    }

    if (map.getSource(id)) return;

    await map.addSource(id, {
      type,
      tiles,
    });

    await map.addLayer({
      id: `${id}-${type}`,
      source: id,
      type,
    });
  };

  const toggleActiveLayer = (visibility) => {
    const map = mapState.current;

    const mapActiveLayers = map.getStyle().layers;

    mapActiveLayers.forEach(({ source, id }) => {
      if (source === baseLayer.current) {
        map.setLayoutProperty(id, 'visibility', visibility); // Display current layer

        if (visibility === 'visible') map.moveLayer(id);
      }
    });
  };

  const moveOverlayLayers = () => {
    mapState.current.getStyle().layers.forEach((layer) => {
      const sourceLayers = layer['source-layer'];

      if (['airspaces', 'airports'].includes(sourceLayers)) mapState.current.moveLayer(layer.id);
    });
  };

  const changeBaseLayer = async () => {
    const layer = layers[mapType];

    try {
      if (!layer) {
        console.error(`Layer ${mapType} is not exist`);
        return;
      }

      const map = mapState.current;

      if (!map) return;

      if (baseLayer.current) toggleActiveLayer('none');

      const { id } = layer;

      const isLayerExisted = map.getSource(id);

      baseLayer.current = id;

      if (!isLayerExisted) {
        await createLayer(layer);
      } else {
        toggleActiveLayer('visible');
      }

      moveOverlayLayers();
    } catch (error) {
      console.error(error);
    } finally {
      if (onBaseLayerChange)
        setTimeout(async () => {
          onBaseLayerChange(layer);
        }, 0);
    }
  };

  const toggleOverlays = async () => {
    const airspaces = attributes.airspacesLayer;
    const airports = attributes.airportsLayer;

    if ((!airspaces && !airports) || !mapState.current) return;

    if (!mapState.current.getSource('openaip-data')) await LayersUtils.addOpenAIPLayers(mapState.current);

    mapState.current.getStyle().layers.forEach((layer) => {
      const sourceLayers = layer['source-layer'];

      if (sourceLayers === 'airspaces')
        mapState.current.setLayoutProperty(layer.id, 'visibility', airspaces ? 'visible' : 'none');

      if (sourceLayers === 'airports')
        mapState.current.setLayoutProperty(layer.id, 'visibility', airports ? 'visible' : 'none');
    });

    moveOverlayLayers();
  };

  useEffect(() => {
    LayersUtils.toggle3DTerrain({ map: mapState.current, visible: terrain3D });
  }, [terrain3D]);

  useEffect(() => {
    changeBaseLayer();
  }, [mapType]);

  useEffect(() => {
    mapInitialization({ onMapClick, onMapLoad });
  }, []);

  useEffect(() => {
    toggleOverlays();
  }, [attributes]);

  return <div className="flight-history-map" style={{ width: '100%', height: '100vh' }} id={MAP_ID}></div>;
};

export default MapboxMap;
