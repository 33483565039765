import { DateTime } from 'luxon';

export const getDateString = (date) => {
  if (!date) return 'Never';

  return new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  }).format(new Date(date));
};

export const getDateTimeString = (date) => {
  if (!date) return 'Never';

  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
    timeZone: 'Etc/GMT',
  }).format(new Date(date));
};

export const formatDateTime = ({ dateStart, dateEnd, timeStart, timeEnd }) => {
  return {
    dateFrom: `${dateStart}T${timeStart}:00.000Z`,
    dateTo: `${dateEnd}T${timeEnd}:00.000Z`,
  };
};

/**
 * @typedef Timezone
 * @property {string} value Zone name
 * @property {string} abbr Zone abbreviation
 * @property {boolean} isdst Shows if zone is dst
 * @property {number} offset Zone offset in hours
 * @property {string[]} utc List of countries/cities with this timezone
 */

/**
 * Create Intl DateTimeFormat for specified timezone to display time
 * @param {Timezone} timezone
 */
export const getAirportTimeFormatter = (timezone, seconds) => {
  const dateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
    timeZone: timezone?.utc[0] || 'Etc/GMT',
  };
  if (seconds) dateTimeFormatOptions.second = '2-digit';
  return new Intl.DateTimeFormat('en-US', dateTimeFormatOptions);
};

/**
 * Create Intl DateTimeFormat for local timezone to display time
 * @param {Timezone} timezone
 */
export const getAirportTimeLocalBrowserFormatter = () => {
  const dateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  };
  return new Intl.DateTimeFormat('en-US', dateTimeFormatOptions);
};

/**
 * Create Intl DateTimeFormat for specified timezone to display time
 * @param {Timezone} timezone
 */
export const getAirportDateFormatter = (timezone) => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: timezone?.utc[0] || 'Etc/GMT',
  });
};

export const fromIsoWithTimezone = (date, timezone) => {
  return DateTime.fromISO(date).setZone(timezone?.utc[0] || 'Etc/GMT');
};

/**
 * Create Intl DateTimeFormat for specified timezone to display time
 * @param {Timezone} timezone
 */
export const getAirportDateTimeFormatter = (timezone, seconds) => {
  const dateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
    timeZone: timezone?.utc[0] || 'Etc/GMT',
  };
  if (seconds) dateTimeFormatOptions.second = '2-digit';
  return new Intl.DateTimeFormat('en-US', dateTimeFormatOptions);
};

/**
 * Create Intl DateTimeFormat for specified timezone to display time with timezone name
 * @param {Timezone} timezone
 */
export const getAirportTimezoneDateTimeFormatter = (timezone) => {
  const dateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
    timeZoneName: 'short',
    timeZone: timezone?.utc[0] || 'Etc/GMT',
  };
  return new Intl.DateTimeFormat('en-US', dateTimeFormatOptions);
};

/**
 *
 * @param {number} monthsAgo by default select current month
 * @param {Function} setFieldValue function to update values
 * @returns {startDateTime, endDateTime} date time range in ISO format
 */
export function getMonthsAgoRangeValues(monthsAgo) {
  const curDate = new Date();
  const ONE_MINUTE_MS = 60000;

  const monthAddition = monthsAgo - 1;

  const startDate = new Date(Date.UTC(curDate.getFullYear(), curDate.getMonth() - (1 + monthAddition), 1));
  const endDate = new Date(
    Date.UTC(curDate.getFullYear(), curDate.getMonth() + (monthsAgo === 0 ? 1 : 0), 1) - ONE_MINUTE_MS
  );

  const startDateTime = startDate.toISOString().slice(0, 16);
  const endDateTime = endDate.toISOString().slice(0, 16);

  return { startDateTime, endDateTime };
}
