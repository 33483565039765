import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openNavbarModal, closeNavbarModal } from 'redux/modules/modal';
import { ReactComponent as ExternalLinkIcon } from 'static/open_window.svg';

import './NavigationList.css';

const NavigationList = ({ navigationList, delimiter = false }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(closeNavbarModal());
  }, [dispatch]);

  const { permissionsList } = useSelector((state) => state.auth.userInfo);
  return navigationList.map(({ sectionName, links, permission }) => {
    if (permission && !permissionsList.includes(permission)) return null;
    return (
      <div key={sectionName}>
        {delimiter && <hr className="navigation__delimiter" />}
        <ul className="navigation__list">
          <li className="navigation-item">
            <div className="navigation-item__heading">{sectionName}</div>
            <ul className="navigation-item__link-list">
              {links
                .filter(({ hide }) => !hide)
                .map((link) => {
                  const { name, path, pathname, modalName, exact = true, external, showDisabled } = link;
                  const isLinkUnderPermission = link.permission && !permissionsList.includes(link.permission);
                  const isLinkWithModal = modalName;

                  const handleClick = isLinkWithModal
                    ? () => {
                        dispatch(openNavbarModal(modalName));
                      }
                    : null;

                  const getLinkContent = () => {
                    return (
                      <>
                        {name}
                        {external && <ExternalLinkIcon className="navigation-item__link-icon" />}
                      </>
                    );
                  };

                  return (
                    <li className="navigation-item__link-li" key={path}>
                      {isLinkUnderPermission ? (
                        showDisabled && (
                          <span
                            className={clsx('navigation-item__link', 'navigation-item__link_disabled')}
                            title="Feature disabled"
                          >
                            {getLinkContent()}
                          </span>
                        )
                      ) : (
                        <NavLink
                          to={pathname ? { pathname } : path}
                          className="navigation-item__link"
                          activeClassName="navigation-item__link_active"
                          exact={exact}
                          onClick={handleClick}
                          target={external ? '_blank' : ''}
                        >
                          {getLinkContent()}
                        </NavLink>
                      )}
                    </li>
                  );
                })}
            </ul>
          </li>
        </ul>
      </div>
    );
  });
};

NavigationList.propTypes = {
  navigationList: PropTypes.arrayOf(
    PropTypes.exact({
      sectionName: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(
        PropTypes.exact({
          name: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
          pathname: PropTypes.string,
          modalName: PropTypes.string,
          exact: PropTypes.bool,
          external: PropTypes.bool,
          showDisabled: PropTypes.bool,
          permission: PropTypes.string,
          hide: PropTypes.bool,
        })
      ).isRequired,
      permission: PropTypes.string,
    })
  ).isRequired,
  delimiter: PropTypes.bool,
};

export default NavigationList;
