import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import CustomCheckbox from './index';

const FormikCheckbox = ({ id, name, ...props }) => {
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
      }) => {
        return <CustomCheckbox checked={field.value} id={id || name} {...field} {...props} />;
      }}
    </Field>
  );
};

FormikCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
};

export default FormikCheckbox;
