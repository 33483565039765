import React, { useRef, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import spacetime from 'spacetime';
import * as htmlToImage from 'html-to-image';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  YAxis,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  CartesianGrid,
} from 'recharts';
import { setChartType } from 'redux/modules/statistic';
import Spinner from 'components/base/Spinner';
import { ChartTypes } from 'utils/statistic.utils';

import { ReactComponent as LineButton } from 'static/chart-line.svg';
import { ReactComponent as BarButton } from 'static/chart-bar.svg';
import { ReactComponent as PieButton } from 'static/chart-pie.svg';
import { ReactComponent as ExportButton } from 'static/chart-export.svg';
import { ReactComponent as Logo } from 'static/logo.svg';

import './StatisticChart.css';

const StatisticChart = (props) => {
  const dispatch = useDispatch();
  const statRef = useRef();
  const {
    chartName,
    lineChartProps,
    barChartProps,
    pieChartProps,
    xAxisProps,
    yAxisProps,
    lineProps,
    barProps,
    pieProps,
    legendProps,
    legendPieProps = {},
    tooltipProps,
    title,
    columns,
    hide = {},
    showRangeSubtitle = true,
    chartHeight = 320,
    subtitle,
    inheritDataKey,
    keyNamesMap = {},
    formatLabel,
    dataModifierFunc,
  } = props;
  const chartColumns = columns || [{ dataKey: 'value', keyValue: 'key', fill: 'var(--blue-500)' }];
  const { timezone, airport } = useSelector((state) => state.auth);
  const chart = useSelector((state) => state.statistic.charts[chartName]);
  const [dateTimeRangeSubtitle, setDateTimeRangeSubtitle] = useState('');
  const [dateTimeRangeFilename, setDateTimeRangeFilename] = useState('');
  const { dataFetched, data, chartType, params = {} } = chart || {};
  const [preventShowLegend, setPreventShowLegend] = useState(false);

  const processedData = (dataModifierFunc && data ? dataModifierFunc(data, params) : data) ?? [];

  let mappedData = (
    columns?.length
      ? processedData
      : processedData?.map(({ key, value }) => ({
          key: keyNamesMap[key] || key,
          value,
        })) || []
  ).map(({ key, ...otherKeys }) => ({
    ...otherKeys,
    key: formatLabel && typeof formatLabel === 'function' ? formatLabel(key) : key,
  }));

  useEffect(() => {
    const { startDateTime, endDateTime, dateFormat } = params;

    // TODO: for future, add after hours to the end of filename in brackets like (LT) or (LT After Hours)
    const isUTC = dateFormat === 'utc';
    const startDateTimeFormatted = spacetime(startDateTime).format('{numeric-us} {time-24}');
    const endDateTimeFormatted = spacetime(endDateTime).format('{numeric-us} {time-24}');

    const startDateTimeISOFormatted = spacetime(startDateTime).format('iso').slice(0, 16);
    const endDateTimeISOFormatted = spacetime(endDateTime).format('iso').slice(0, 16);

    const dateFormatName = isUTC ? 'UTC' : 'LT';
    const formattedString = `${airport?.icao} ${startDateTimeFormatted} > ${endDateTimeFormatted} ${dateFormatName}`;
    const formattedFilenameString = `${startDateTimeISOFormatted} - ${endDateTimeISOFormatted} (${dateFormatName})`;

    setDateTimeRangeSubtitle(formattedString);
    setDateTimeRangeFilename(formattedFilenameString);
  }, [timezone, airport, params]);

  const renderAxes = () => {
    return (
      <>
        <CartesianGrid strokeDasharray="3 3" opacity={0.3} />

        <XAxis fontSize="13px" dataKey="key" stroke="#6b7279" interval={0} {...xAxisProps} />
        <YAxis fontSize="13px" dataKey={inheritDataKey ? '' : 'value'} stroke="#6b7279" {...yAxisProps} />
      </>
    );
  };

  const renderUtils = () => {
    return (
      <>
        {(legendProps || preventShowLegend) && <Legend {...legendProps} iconSize={12} iconType={'square'} />}
        {tooltipProps && <Tooltip {...tooltipProps} cursor={{ fill: 'rgba(71, 186, 236, 0.1)' }} />}
      </>
    );
  };
  const COLORS = [
    'var(--blue-500)',
    'var(--yellow-500)',
    'var(--green-500)',
    'var(--purple-500)',
    'var(--magenta-500)',
    'var(--warm-grey-500)',
    'var(--green-400)',
    'var(--purple-400)',
    'var(--blue-400)',
    'var(--yellow-400)',
    'var(--magenta-400)',
    'var(--warm-grey-400)',
  ];

  const renderChart = () => {
    switch (chartType) {
      case ChartTypes.PIE:
        return (
          <PieChart {...pieChartProps}>
            <Pie
              data={mappedData}
              dataKey="value"
              cx="40%"
              nameKey="key"
              minAngle={3}
              label
              innerRadius={60}
              {...{ cx: '40%', ...pieProps }}
            >
              {mappedData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} stroke="none" />
              ))}
            </Pie>
            {(legendPieProps || preventShowLegend) && (
              <Legend
                {...{
                  layout: 'vertical',
                  verticalAlign: 'middle',
                  iconType: 'square',
                  align: 'right',
                  ...legendPieProps,
                }}
              />
            )}
            {renderUtils()}
          </PieChart>
        );
      case ChartTypes.BAR:
        return (
          <BarChart {...barChartProps} data={mappedData} cursor={{ fill: '#f00' }}>
            {renderAxes()}
            {chartColumns.map((item) => (
              <Bar dataKey={item.dataKey} key={item.dataKey} fill={item.fill} {...{ barSize: 25, ...barProps }} />
            ))}
            {renderUtils()}
          </BarChart>
        );

      case ChartTypes.LINE:
      default:
        return (
          <LineChart {...lineChartProps} data={mappedData}>
            {renderAxes()}
            {chartColumns.map((item) => (
              <Line dataKey={item.dataKey} key={item.dataKey} stroke={item.fill} fill={item.fill} {...lineProps} />
            ))}
            {renderUtils()}
          </LineChart>
        );
    }
  };

  const downloadPng = useCallback(() => {
    if (statRef.current === null) {
      return;
    }

    setPreventShowLegend(true);
    const fileName = `virtower - Statistics - ${title} ${dateTimeRangeFilename}.png`;
    statRef.current.classList.add('chart_print');

    htmlToImage
      .toPng(statRef.current, { cacheBust: true })
      .then((dataUrl) => {
        saveAs(dataUrl, fileName);
      })
      .catch((err) => {
        console.error(err);
        toast.error(`Cannot be exported to image. ${err?.message}`);
      })
      .finally(() => {
        statRef.current.classList.remove('chart_print');
        setPreventShowLegend(false);
      });
  }, [dateTimeRangeFilename, title, statRef]);

  return (
    <div className="chart" id={`statistic-chart-${chartName}`} ref={statRef}>
      <div className="chart__header">
        <div className="chart__info">
          <h4 className="chart__title">{title}</h4>
          {showRangeSubtitle && <h5 className="chart__subtitle">{dateTimeRangeSubtitle}</h5>}
          <h5 className="chart__subtitle">{subtitle}</h5>
        </div>
        <div className="chart__actions">
          {!hide.lineButton && (
            <button
              className={clsx('chart__button', {
                'chart-button_active': chartType === ChartTypes.LINE,
              })}
              onClick={() => dispatch(setChartType({ chartName, chartType: ChartTypes.LINE }))}
            >
              <LineButton />
            </button>
          )}
          {!hide.barButton && (
            <button
              className={clsx('chart__button', {
                'chart-button_active': chartType === ChartTypes.BAR,
              })}
              onClick={() => dispatch(setChartType({ chartName, chartType: ChartTypes.BAR }))}
            >
              <BarButton />
            </button>
          )}
          {!hide.pieButton && (
            <button
              className={clsx('chart__button', {
                'chart-button_active': chartType === ChartTypes.PIE,
              })}
              onClick={() => dispatch(setChartType({ chartName, chartType: ChartTypes.PIE }))}
            >
              <PieButton />
            </button>
          )}
          <button className={clsx('chart__button', 'chart__button_export')} onClick={downloadPng}>
            <ExportButton />
          </button>
        </div>
      </div>
      {dataFetched ? (
        data?.length ? (
          <ResponsiveContainer className={'chart__graph px-4'} height={chartHeight}>
            {renderChart()}
          </ResponsiveContainer>
        ) : (
          <div className="chart-no-data" style={{ height: chartHeight + 'px' }}>
            No Data
          </div>
        )
      ) : (
        <div className="chart__spinner">
          <Spinner />
        </div>
      )}
      <Logo className="chart__watermark" />
    </div>
  );
};

StatisticChart.propTypes = {
  chartName: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  xAxisProps: PropTypes.shape({}),
  yAxisProps: PropTypes.shape({}),
  pieChartProps: PropTypes.shape({}),
  barChartProps: PropTypes.shape({}),
  lineChartProps: PropTypes.shape({}),
  lineProps: PropTypes.shape({}),
  barProps: PropTypes.shape({}),
  pieProps: PropTypes.shape({}),
  legendProps: PropTypes.shape({}),
  legendPieProps: PropTypes.oneOfType([
    PropTypes.shape({}), // Объект с произвольной структурой
    PropTypes.bool, // Массив объектов
  ]),
  tooltipProps: PropTypes.shape({}),
  keyNamesMap: PropTypes.shape({
    key: PropTypes.string,
  }),
  dataModifierFunc: PropTypes.func,
};

export default StatisticChart;
