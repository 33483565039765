import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as Add } from './addIcon.svg';

const TableAddButton = ({ path }) => {
  return (
    <Link className="container__button" type="button" to={path}>
      <Add />
      ADD
    </Link>
  );
};

TableAddButton.propTypes = {
  path: PropTypes.string,
};

export default TableAddButton;
