import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import TableGlobalFilter from 'components/common/CustomTable/TableFilters/TableGlobalFilter';
import TableAddButton from 'components/common/CustomTable/TableAddButton';
import TableReloadButton from 'components/common/CustomTable/TableReloadButton';
import TableResetButton from 'components/common/CustomTable/TableResetButton';

import './Container.css';

const Container = ({
  children,
  className,
  title,
  titleHelpText,
  actionHelpText,
  bottomHelpText,
  tableSearch = false,
  tableAddButtonPath,
  globalFilter,
  setGlobalFilter,
  resetTableName,
  tableReload,
  actions,
  fixedTableFooter,
  row,
  scrollable,
}) => {
  return (
    <div
      className={clsx('container', className, {
        'container_fixed-footer': fixedTableFooter,
        container_row: row,
        container_scrollable: scrollable,
      })}
    >
      {title && (
        <div className={clsx('container-title')}>
          <div className="container-title_block">
            {title}
            {titleHelpText && <div className="container-title_block_help" title={titleHelpText} />}
          </div>
          <div className="container-title_block container-title_block_push-right">
            {actionHelpText && <span className="container__help-text_action">{actionHelpText}</span>}
            {actions?.map((child) =>
              React.cloneElement(child, {
                className: `${child.props.className} container__button`,
                key: child.props.children,
              })
            )}
            {resetTableName && <TableResetButton tableName={resetTableName} />}
            {tableReload && <TableReloadButton onClick={tableReload} />}
            {tableAddButtonPath && <TableAddButton path={tableAddButtonPath} />}
            {tableSearch && <TableGlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />}
          </div>
        </div>
      )}
      <div className={clsx('container-content', { 'container-content_no-title': !title })}>{children}</div>
      {bottomHelpText && (
        <div className="container-bottom_help_container">
          <span className="container__help-text_bottom">{bottomHelpText}</span>
        </div>
      )}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  tableSearch: PropTypes.bool,
  title: PropTypes.string,
  titleHelpText: PropTypes.string,
  actionHelpText: PropTypes.string,
  bottomHelpText: PropTypes.string,
  tableAddButtonPath: PropTypes.string,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  resetTableName: PropTypes.string,
  tableReload: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,

  fixedTableFooter: PropTypes.bool,
  row: PropTypes.bool,
  scrollable: PropTypes.bool,
};

export default Container;
