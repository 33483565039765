export const ChartNames = {
  OPS_PER_VISITING: 'opsPerVisitingBased',
  OPS_PER_ICON_TYPE: 'opsPerIconType',
  OPS_PER_HOUR: 'opsPerHour',
  OPS_PER_DOW: 'opsPerDow',
  OPS_PER_RWY: 'opsPerRunway',
  OPS_PER_RWY_TO: 'opsPerRunwayTakeOff',
  OPS_PER_RWY_LDG: 'opsPerRunwayLanding',
  OPS_BY_ACTIVITY_FIRST: 'opsByActivityFirst',
  OPS_BY_ACTIVITY_SECOND: 'opsByActivitySecond',

  TAKEOFF_ST: 'takeOffStatistics',
  LANDING_ST: 'landingStatistics',
};

export const ChartTypes = {
  LINE: 'line',
  BAR: 'bar',
  PIE: 'pie',
};
