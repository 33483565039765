// TODO: fix eslint
/* eslint-disable */
import api from 'api/api';
import AccordionTab from 'components/base/AccordionTab';
import Button from 'components/base/Button';
import CustomSelect from 'components/base/CustomSelect';
import {
  ALTITUDE_TYPE_GNSS_CORRECTED,
  ALTITUDE_TYPE_GNSS_RAW,
  ALTITUDE_TYPE_QFE_CORRECTED,
  ALTITUDE_TYPE_QNH_CORRECTED,
  // ALTITUDE_TYPE_QNHS_CORRECTED,
  ALTITUDE_TYPE_RAW,
} from 'pages/NoiseAbatement/noiseAbatementHelpers';
import React, { useState } from 'react';
import { GeometryUtils } from 'utils/geometry.utils';

const exportTypes = [
  {
    name: 'KML',
    value: 'kml',
  },
  {
    name: 'KMZ',
    value: 'kmz',
  },
  {
    name: 'KMZ with Waypoints',
    value: 'kmzWithWaypoints',
  },
];

const altitudeTypeOptions = [
  { name: 'Raw Altitude (Uncorrected)', value: ALTITUDE_TYPE_RAW },
  { name: 'GNSS Altitude (Above Ellipsoid)', value: ALTITUDE_TYPE_GNSS_RAW },
  { name: 'GNSS Altitude (Geoid-Corrected, MSL)', value: ALTITUDE_TYPE_GNSS_CORRECTED },
  { name: 'Barometric Altitude (QNH, MSL)', value: ALTITUDE_TYPE_QNH_CORRECTED },
  { name: 'Barometric Altitude (QFE, MSL)', value: ALTITUDE_TYPE_QFE_CORRECTED },
  // { name: 'QNHS Corrected', value: ALTITUDE_TYPE_QNHS_CORRECTED },
];

const FlightHistoryExport = ({ map, pointsData, exportDisabled }) => {
  const [dataType, setDataType] = useState('kml');
  const [altitudeType, setAltitudeType] = useState('clampToGround');
  const [isTabOpened, setTabOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onTabToggle = (value) => setTabOpened(value);

  const loadFlightDataRegistrations = async () => {
    setIsLoading(true);
    try {
      const addrs = new Set(pointsData.features.map(({ properties }) => properties.transponder_addr));

      return api.getRegistrationsByAddr({ addrs: Array.from(addrs) });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const exportData = async () => {
    const data = map.getSource('history-source')._data;

    if (dataType === 'kml') {
      GeometryUtils.downloadKML(data);
    } else if (dataType === 'kmz') {
      GeometryUtils.downloadKMZ(data);
    } else if (dataType === 'kmzWithWaypoints') {
      const registrations = await loadFlightDataRegistrations();

      pointsData.features = pointsData.features.map((item) => {
        item.properties.registration = registrations[item.properties.transponder_addr];

        return item;
      });

      GeometryUtils.downloadKMZ(data, [pointsData], 'kmz-export-with-waypoints');
    }
  };

  const onDataTypeChange = (data) => {
    setDataType(data);
  };

  const onAltitudeTypeChange = (data) => {
    setAltitudeType(data);
  };

  return (
    <div className="flight-history-tab flight-history-block--export">
      <AccordionTab label="Export" name="test" opened={isTabOpened} setOpened={onTabToggle}>
        <div className="flight-history-data">
          <div className="flight-history-data__label">Altitude Type</div>
          <CustomSelect options={altitudeTypeOptions} className="flight-history-mb-5" onChange={onAltitudeTypeChange} />
        </div>

        <div className="flight-history-data">
          <div className="flight-history-data__label">File Type</div>
          <CustomSelect className="flight-history-mb-15" options={exportTypes} onChange={onDataTypeChange} />
        </div>
        <Button
          mods={{ action: true }}
          className="noise_button"
          disabled={exportDisabled || isLoading}
          type="submit"
          onClick={exportData}
        >
          Export
        </Button>
      </AccordionTab>
    </div>
  );
};

export { FlightHistoryExport };
