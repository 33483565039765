import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { resetTable } from 'redux/modules/tables';

const TableResetButton = ({ tableName }) => {
  const dispatch = useDispatch();

  return (
    <button
      className="container__button"
      type="button"
      onClick={() => {
        dispatch(resetTable(tableName));
      }}
    >
      RESET
    </button>
  );
};

TableResetButton.propTypes = {
  tableName: PropTypes.string,
};

export default TableResetButton;
