import Container from 'components/base/Container';
import Page from 'components/common/Page';
import StatisticChart from 'pages/Statistics/StatisticChart';
import StatisticProvider from 'pages/Statistics/StatisticProvider';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ChartNames } from 'utils/statistic.utils';
import '../Statistics/Statistics.css';
import DateAndTime from './DateAndTime';
import { getMonthsAgoRangeValues, formatDateTime } from 'utils/dateTimeHelpers';
import api from 'api/api';
import { setChartData, setChartDataFetched, setStatisticParams } from 'redux/modules/statistic';
import './Graphs.css';
import GraphDropdown from './GraphDropdown';

const currentYear = new Date().getFullYear();

const columns = [
  { dataKey: currentYear - 2, keyValue: 'key', fill: '#24c1ff' },
  { dataKey: currentYear - 1, keyValue: 'key', fill: '#acc717' },
  { dataKey: currentYear, keyValue: 'key', fill: '#ff8500' },
];

const chartNameEndpointNameMap = {
  [ChartNames.OPS_BY_ACTIVITY]: 'ops-by-activities',
  [ChartNames.LANDING_ST]: 'landed-activities',
  [ChartNames.TAKEOFF_ST]: 'takeoff-activities',
};

const chartNameTitleMap = {
  [ChartNames.OPS_BY_ACTIVITY]: 'Operations by Activity Selected',
  [ChartNames.LANDING_ST]: 'Landings',
  [ChartNames.TAKEOFF_ST]: 'Takeoffs',
};

const initialDatesOneMonthAgo = getMonthsAgoRangeValues(1);

const initialValues = {
  dateFormat: 'local',
  dateStart: initialDatesOneMonthAgo.startDateTime.slice(0, 10),
  dateEnd: initialDatesOneMonthAgo.endDateTime.slice(0, 10),
  timeStart: initialDatesOneMonthAgo.startDateTime.slice(11, 16),
  timeEnd: initialDatesOneMonthAgo.endDateTime.slice(11, 16),
};

const Graphs = () => {
  const dispatch = useDispatch();
  const dateValue = useRef(initialValues);
  const requestTimeout = useRef();

  const [selectedActivities, setSelectedActivities] = useState([]);

  const onDateSubmit = (values) => {
    dateValue.current = { ...dateValue.current, ...values };

    const { dateFrom, dateTo } = formatDateTime(values);

    dispatch(
      setStatisticParams({
        chartName: ChartNames.OPS_BY_ACTIVITY,
        startDateTime: dateFrom,
        endDateTime: dateTo,
      })
    );
  };

  const onActivityChange = async (value) => {
    clearTimeout(requestTimeout.current);

    setSelectedActivities(value);

    requestTimeout.current = setTimeout(async () => {
      const { dateFrom, dateTo } = formatDateTime(dateValue.current);
      dispatch(setChartDataFetched({ chartName: ChartNames.OPS_BY_ACTIVITY, dataFetched: false }));

      const data = await api.getStatisticsOperationsBased({
        startDateTime: dateFrom,
        endDateTime: dateTo,
        activityIds: getActivitiesIds(value),
      });

      dispatch(setChartData({ chartName: ChartNames.OPS_BY_ACTIVITY, data }));
      dispatch(setChartDataFetched({ chartName: ChartNames.OPS_BY_ACTIVITY, dataFetched: true }));
    }, 1000);
  };

  const getActivitiesIds = (data) => {
    return data.map(({ value }) => value);
  };

  const labelFormatter = (label) => {
    return label.slice(0, 3);
  };

  return (
    <Page>
      <DateAndTime initialDate={initialValues} onSubmit={onDateSubmit} />
      <div className="pr-8">
        <Container title="Operations" className="graphs__container border-none mb-28">
          <div className="stats-grid">
            <StatisticProvider
              query={{ activityIds: getActivitiesIds(selectedActivities) }}
              showDatePicker={false}
              initialDate={initialValues}
              chartName={ChartNames.OPS_BY_ACTIVITY}
              endpointName={chartNameEndpointNameMap[ChartNames.OPS_BY_ACTIVITY]}
            >
              <StatisticChart
                legendPieProps={false}
                chartName={ChartNames.OPS_BY_ACTIVITY}
                title={chartNameTitleMap[ChartNames.OPS_BY_ACTIVITY]}
                tooltipProps={{}}
                pieProps={{ innerRadius: 0 }}
                // legendPieProps={{ layout: 'horizontal', align: 'center', verticalAlign: 'bottom' }}
              />
              <GraphDropdown onActivityChange={onActivityChange} />
            </StatisticProvider>
          </div>
        </Container>
        <Container title="Three-year Comparison" className="border-none">
          <div className="stats-grid">
            <StatisticProvider
              showDatePicker={false}
              chartName={ChartNames.LANDING_ST}
              endpointName={chartNameEndpointNameMap[ChartNames.LANDING_ST]}
            >
              <StatisticChart
                legendProps={{}}
                showRangeSubtitle={false}
                inheritDataKey
                formatLabel={labelFormatter}
                columns={columns}
                barProps={{
                  barSize: 15,
                }}
                chartName={ChartNames.LANDING_ST}
                title={chartNameTitleMap[ChartNames.LANDING_ST]}
                tooltipProps={{}}
              />
            </StatisticProvider>
            <StatisticProvider
              showDatePicker={false}
              chartName={ChartNames.TAKEOFF_ST}
              endpointName={chartNameEndpointNameMap[ChartNames.TAKEOFF_ST]}
            >
              <StatisticChart
                legendProps={{}}
                showRangeSubtitle={false}
                inheritDataKey
                formatLabel={labelFormatter}
                columns={columns}
                barProps={{
                  barSize: 15,
                }}
                chartName={ChartNames.TAKEOFF_ST}
                title={chartNameTitleMap[ChartNames.TAKEOFF_ST]}
                tooltipProps={{}}
              />
            </StatisticProvider>
          </div>
        </Container>
      </div>
    </Page>
  );
};

export default Graphs;
