import Button from 'components/base/Button';
import FormikCheckbox from 'components/base/Checkbox/FormikCheckbox';
import FormikInput from 'components/base/CustomInput/FormikInput';
import CustomSelect from 'components/base/CustomSelect';
import FormField from 'components/base/FormField';
import FormGroup from 'components/base/FormGroup';
import { Formik } from 'formik';
import React, { useState } from 'react';
import './DateAndTime.css';
import Container from 'components/base/Container';

const presetsOptions = [
  { name: 'Last Year', value: 'lastYear' },
  { name: 'Last Quater', value: 'lastQuater' },
  { name: 'Last Month', value: 'lastMonth' },
  { name: 'Last Week', value: 'lastWeek' },
  { name: 'Yesterday', value: 'yesterday' },
  { name: 'Today', value: 'today' },
];

const getPresetDates = () => {
  const now = new Date();

  // Create a new Date object for calculations to avoid modifying the original
  const calcDate = new Date(now);

  const presets = {
    lastYear: {
      // Last year should be the entire previous calendar year (Jan 1 to Dec 31)
      dateStart: new Date(now.getFullYear() - 1, 0, 2).toISOString().slice(0, 10),
      dateEnd: new Date(now.getFullYear() - 1, 11, 32).toISOString().slice(0, 10),
      timeStart: '00:00',
      timeEnd: '23:59',
    },
    lastQuater: {
      // A full year starting from today
      dateEnd: now.toISOString().slice(0, 10),
      dateStart: new Date(now.getFullYear(), now.getMonth() - 2, now.getDate()).toISOString().slice(0, 10),
      timeStart: '00:00',
      timeEnd: '23:59',
    },
    lastMonth: {
      // Last month should be the previous calendar month
      dateStart: new Date(now.getFullYear(), now.getMonth() - 1, 2).toISOString().slice(0, 10),
      dateEnd: new Date(now.getFullYear(), now.getMonth(), 1).toISOString().slice(0, 10),
      timeStart: '00:00',
      timeEnd: '23:59',
    },
    monthFromToday: {
      dateStart: new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).toISOString().slice(0, 10),
      dateEnd: new Date(now.getFullYear(), now.getMonth() + 1, now.getDate() + 1).toISOString().slice(0, 10),
      timeStart: '00:00',
      timeEnd: '23:59',
    },
    lastWeek: {
      // Last 7 days (without modifying the original date)
      dateStart: new Date(calcDate.setDate(now.getDate() - 7)).toISOString().slice(0, 10),
      dateEnd: now.toISOString().slice(0, 10),
      timeStart: '00:00',
      timeEnd: '23:59',
    },
    yesterday: {
      // Next 7 days (create a new calculation date to avoid date incrementation issues)
      dateEnd: new Date().toISOString().slice(0, 10),
      dateStart: new Date(now.getFullYear(), now.getMonth(), now.getDate() + 0.5).toISOString().slice(0, 10),
      timeStart: '00:00',
      timeEnd: '23:59',
    },
    today: {
      // Today's date
      dateStart: now.toISOString().slice(0, 10),
      dateEnd: now.toISOString().slice(0, 10),
      timeStart: '00:00',
      timeEnd: '23:59',
    },
  };

  return presets;
};

const presets = getPresetDates();

const DateAndTime = ({ initialDate, onSubmit }) => {
  const [preset, setPreset] = useState('lastMonth');

  const onPresetChange = ({ value, setValues, values }) => {
    setValues({ ...values, ...presets[value] });

    setPreset();
  };

  return (
    <Container className="mb-28" title="Date & Time Period">
      <div className="flex gap-5 w-full bg-dark p-6 rounded-md">
        <Formik initialValues={initialDate} validateOnBlur={false} validateOnChange={false}>
          {({ values, setValues, submitForm }) => (
            <>
              <div className="border-r border-solid border-gr-dark pr-5 w-full max-w-72">
                <div className="text-gr text-xs1 mb-2">Presets</div>
                <CustomSelect
                  name="activityFilter"
                  options={presetsOptions}
                  value={preset}
                  onChange={(value) => onPresetChange({ value, setValues, values })}
                />
              </div>
              <FormGroup className="max-w-sm w-full border-r border-solid border-dark pr-5 mb-0">
                <div className="flex flex-col gap-4 ">
                  <div className="flex items-center">
                    <FormField label={`Date (from)`} name="dateStart">
                      <FormikInput
                        name="dateStart"
                        type="date"
                        pattern="^\d{4}-\d{2}-\d{2}$"
                        required
                        clearErrorsOnChange
                      />
                    </FormField>
                    <div className="graphs-form_triangle mr-4 ml-5 mt-6" />
                    <FormField label={`Date (to) `} name="dateEnd">
                      <FormikInput
                        name="dateEnd"
                        type="date"
                        pattern="^\d{4}-\d{2}-\d{2}$"
                        required
                        clearErrorsOnChange
                      />
                    </FormField>
                  </div>

                  <div className="flex items-center">
                    <FormField label={`Time (from)`} name="timeStart">
                      <FormikInput
                        name="timeStart"
                        type="time"
                        pattern="^([2][0-3]|[01]?[0-9])([:][0-5][0-9])?$"
                        required
                        clearErrorsOnChange
                      />
                    </FormField>
                    <div className="graphs-form_triangle mr-4 ml-5 mt-6" />

                    <FormField label={`Time (to)`} name="timeEnd">
                      <FormikInput
                        name="timeEnd"
                        type="time"
                        pattern="^([2][0-3]|[01]?[0-9])([:][0-5][0-9])?$"
                        required
                        clearErrorsOnChange
                      />
                    </FormField>
                  </div>
                  <div className="flex items-center">
                    <FormField
                      label="UTC / Zulu"
                      name="timeFormatUTC"
                      className="flex items-start flex-row-reverse mr-4 flex-none"
                    >
                      <FormikCheckbox
                        name="dateFormat"
                        id="timeFormatUTC"
                        className="mr-2"
                        type="radio"
                        value="utc"
                        checked={values.dateFormat === 'utc'}
                      />
                    </FormField>
                    <FormField
                      label="Local Time"
                      name="timeFormatLocal"
                      className="flex items-start flex-row-reverse mr-2 flex-none"
                    >
                      <FormikCheckbox
                        name="dateFormat"
                        id="timeFormatLocal"
                        className="mr-2"
                        type="radio"
                        value="local"
                        checked={values.dateFormat === 'local'}
                      />
                    </FormField>
                  </div>
                </div>
              </FormGroup>
              <Button
                className="mt-auto max-w-24 mb-[40px] ml-[54px]"
                mods={{ action: true }}
                onClick={() => onSubmit(values)}
              >
                Lookup
              </Button>
            </>
          )}
        </Formik>
      </div>
    </Container>
  );
};

export default DateAndTime;
