import api from 'api/api';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import { setChartDataFetched, setStatisticParams } from 'redux/modules/statistic';
import { useLocalStorage } from 'usehooks-ts';

const ACTIVITIES_LIMIT = 10;

const getActivitiesIds = (data) => {
  return data.map(({ value }) => value);
};

const colorStyles = {
  option: (styles, { isSelected, isFocused, ...params }) => {
    return {
      ...styles,
      cursor: 'pointer',
      backgroundColor: isSelected ? 'var(--blue-500)' : isFocused ? '#ddd' : 'var(--grey-100)',
      color: 'black',
      ':hover': {
        backgroundColor: 'rgba(36, 193, 255, 0.5)',
      },
    };
  },
  control: (styles) => ({
    ...styles,
    backgroundColor: 'var(--grey-100)',
    minHeight: '30px',
    height: '35px',
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: 'var(--grey-100)', // 🔴 Фон выпадающего списка
  }),
  menuList: (provided) => ({
    ...provided,
    scrollbarColor: '#9e9e9e var(--grey-100)', // Цвет ползунка и трека в Firefox
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '4px',
  }),
};

const colors = [
  'var(--blue-500)',
  'var(--yellow-500)',
  'var(--green-500)',
  'var(--purple-500)',
  'var(--magenta-500)',
  'var(--warm-grey-500)',
  'var(--green-400)',
  'var(--purple-400)',
  'var(--blue-400)',
  'var(--yellow-400)',
  'var(--magenta-400)',
  'var(--warm-grey-400)',
];

const MenuList = (props) => {
  const [search, setSearch] = useState('');

  const filteredChildren = props.children.filter((child) =>
    child.props.data.label.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <components.MenuList {...props}>
      <div style={{ padding: '8px' }}>
        <input
          type="text"
          placeholder="Search..."
          className="py-1 px-2 w-full outline-none rounded-sm"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onMouseDown={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
        />
      </div>
      {filteredChildren.length > 0 ? filteredChildren : <div></div>}
    </components.MenuList>
  );
};

const GraphDropdown = ({ chartName }) => {
  const [activities, setActivities] = useState([]);
  const [selectedActivities, setSelectedActivities] = useLocalStorage(chartName, []);
  const selectRef = useRef(null);
  const dispatch = useDispatch();
  const requestTimeout = useRef();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  const onActivityChange = async (value, actionMeta) => {
    clearTimeout(requestTimeout.current);

    let activities = [];

    if (selectedActivities.length >= ACTIVITIES_LIMIT) {
      const currentActivity = selectedActivities.find(({ value }) => value === actionMeta?.option?.value);

      if (currentActivity) {
        activities = value.filter((option) => option.value !== actionMeta?.option?.value);
      } else {
        return;
      }
    } else {
      activities = value;
    }

    setSelectedActivities(activities);

    requestTimeout.current = setTimeout(async () => {
      dispatch(setChartDataFetched({ chartName, dataFetched: false }));

      dispatch(setStatisticParams({ chartName, activityIds: getActivitiesIds(activities) }));
      dispatch(setChartDataFetched({ chartName, dataFetched: true }));
    }, 1000);
  };

  const loadActivities = async () => {
    const response = await api.getReportsData();

    const data = response.eventNames.map(({ eventName, id }, index) => ({
      label: eventName,
      value: id,
    }));

    setActivities(data);
  };

  const deleteOption = ({ value }) => {
    const data = selectedActivities.filter((option) => option.value !== value);

    setSelectedActivities(data);
    dispatch(setStatisticParams({ chartName, activityIds: getActivitiesIds(data) }));
  };

  useEffect(() => {
    loadActivities();

    dispatch(setStatisticParams({ chartName, activityIds: getActivitiesIds(selectedActivities) }));

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const ValueContainer = ({ ...props }) => (
    <components.ValueContainer {...props}>
      <div className="text-gray-700 px-4 text-sm mb-0.5 w-full cursor-pointer" onClick={() => setMenuOpen(!menuOpen)}>
        {selectedActivities.length
          ? `Selected +${selectedActivities.length} activities`
          : 'Activities available at your airport'}
      </div>
    </components.ValueContainer>
  );

  return (
    <div className="bg-dark -mt-2 rounded-md px-24 pb-20">
      <div className="p-3" ref={selectRef}>
        <Select
          isMulti
          height={'30px'}
          closeMenuOnSelect={false}
          options={activities}
          value={selectedActivities}
          onChange={onActivityChange}
          hideSelectedOptions={false}
          menuIsOpen={menuOpen}
          onMenuOpen={() => setMenuOpen(true)}
          onMenuClose={() => setMenuOpen(false)}
          menuPosition={'fixed'}
          styles={colorStyles}
          components={{
            MenuList,
            ValueContainer,
          }}
        ></Select>
      </div>
      <div className="flex flex-wrap p-3 gap-3 relative">
        {selectedActivities.map((option, index) => (
          <div
            key={option.label}
            className="p-2 rounded-md border border-solid"
            style={{
              color: colors[index],
              borderColor: colors[index],
            }}
          >
            {option.label}
            <span className="ml-1.5 cursor-pointer" onClick={() => deleteOption(option)}>
              &times;
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GraphDropdown;
