import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import spacetime from 'spacetime';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import api from 'api/api';
import { setChartDataFetched, setChartData } from 'redux/modules/statistic';
import DateTimeRangeForm from '../DateTimeRangeForm';

const StatisticProvider = ({ chartName, endpointName, children, initialDate, showDatePicker = true }) => {
  const dispatch = useDispatch();
  const chart = useSelector((state) => state.statistic.charts[chartName]);
  const { timezone, airport } = useSelector((state) => state.auth);
  const { params } = chart || {};

  useEffect(() => {
    if (params) {
      const { startDateTime: start, endDateTime: end, dateFormat, activityIds } = params;

      const isUTC = dateFormat === 'utc';
      const [timezoneName] = timezone.utc;

      const startDateTime = spacetime(start, isUTC ? 'Etc/GMT' : timezoneName).format('iso-utc');
      const endDateTime = spacetime(end, isUTC ? 'Etc/GMT' : timezoneName).format('iso-utc');

      const afterHoursParams = {};
      if (dateFormat === 'localAfterHours') {
        afterHoursParams.airportFromHours = airport.fromHours;
        afterHoursParams.airportToHours = airport.toHours;
      }

      const statsParams = {
        startDateTime,
        endDateTime,
        activityIds,
        ...afterHoursParams,
      };

      dispatch(setChartDataFetched({ chartName, dataFetched: false }));
      api
        .getStats(endpointName, statsParams)
        .then((data) => {
          dispatch(setChartData({ chartName, data }));
        })
        .catch((error) => {
          toast.error(`Error while loading ${chartName}. ${error?.message}`);
        })
        .finally(() => {
          dispatch(setChartDataFetched({ chartName, dataFetched: true }));
        });
    }
  }, [params, timezone, airport, chartName, endpointName]);

  return (
    <div className="chart__wrapper">
      {children}
      {showDatePicker && <DateTimeRangeForm chartName={chartName} values={initialDate} />}
    </div>
  );
};

StatisticProvider.propTypes = {
  chartName: PropTypes.string.isRequired,
  endpointName: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default StatisticProvider;
