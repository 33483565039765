import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from 'components/base/Button';
import FormField from 'components/base/FormField';
import FormikInput from 'components/base/CustomInput/FormikInput';
import FormikCheckbox from 'components/base/Checkbox/FormikCheckbox';
import { setStatisticParams } from 'redux/modules/statistic';

import './DateTimeRangeForm.css';

const DateTimeRangeForm = ({ chartName, values }) => {
  const dispatch = useDispatch();

  const chart = useSelector((state) => state.statistic.charts[chartName]);
  const { params = {} } = chart || {};

  const initialValues = values
    ? values
    : params?.startDateTime
    ? {
        dateStart: params.startDateTime.slice(0, 10),
        dateEnd: params.endDateTime.slice(0, 10),
        timeStart: params.startDateTime.slice(11, 16),
        timeEnd: params.endDateTime.slice(11, 16),
        dateFormat: params.dateFormat || 'local',
      }
    : {
        dateFormat: 'local',
      };

  const reportFormValidation = (values) => {
    const errors = {};

    if (values.dateStart && values.dateEnd) {
      const dateStart = new Date(values.dateStart);
      const dateEnd = new Date(values.dateEnd);

      if (isNaN(dateStart.getTime()) || isNaN(dateStart.getTime()) || dateEnd < dateStart) {
        errors.dateStart = 'Incorrect range.';
        errors.dateEnd = 'Incorrect range.';

        toast.error('Incorrect date range. Please verify the values you entered.');
      }
    }

    return errors;
  };

  const formOnSubmit = (values, { setSubmitting }) => {
    const { dateStart, dateEnd, timeStart, timeEnd, dateFormat } = values;

    const startDateTime = `${dateStart}T${timeStart}`;
    const endDateTime = `${dateEnd}T${timeEnd}`;

    const statisticParams = {
      startDateTime,
      endDateTime,
      dateFormat,
      chartName,
    };
    dispatch(setStatisticParams(statisticParams));

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={reportFormValidation}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={formOnSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form className="datetime__form" id="dateTimeRangeForm">
          <div className="datetime__form-group">
            <FormField
              label={`Date${navigator.vendor === 'Apple Computer, Inc.' ? ` (YYYY-MM-DD)` : ''}`}
              name="dateStart"
              mods={{ condensed: true }}
            >
              <FormikInput name="dateStart" type="date" pattern="^\d{4}-\d{2}-\d{2}$" required condensed />
            </FormField>
            <FormField
              label={`Time${navigator.vendor === 'Apple Computer, Inc.' ? ` (HH:MM)` : ''}`}
              name="timeStart"
              mods={{ condensed: true }}
            >
              <FormikInput
                name="timeStart"
                type="time"
                pattern="^([2][0-3]|[01]?[0-9])([:][0-5][0-9])?$"
                required
                condensed
              />
            </FormField>

            <div className="next-step-triangle"></div>

            <FormField
              label={`Date${navigator.vendor === 'Apple Computer, Inc.' ? ` (YYYY-MM-DD)` : ''}`}
              name="dateEnd"
              mods={{ condensed: true }}
            >
              <FormikInput name="dateEnd" type="date" pattern="^\d{4}-\d{2}-\d{2}$" required condensed />
            </FormField>
            <FormField
              label={`Time${navigator.vendor === 'Apple Computer, Inc.' ? ` (HH:MM)` : ''}`}
              name="timeEnd"
              mods={{ condensed: true }}
            >
              <FormikInput
                name="timeEnd"
                type="time"
                pattern="^([2][0-3]|[01]?[0-9])([:][0-5][0-9])?$"
                required
                condensed
              />
            </FormField>
            <Button
              mods={{ action: true, loading: isSubmitting, small: true }}
              disabled={isSubmitting}
              type="submit"
              className="datetime-form__submit"
            >
              {'Set'}
            </Button>
            <div className="datetime-form_checkbox-group">
              <FormField label="UTC" name="timeFormatUTC" className="datetime__form-checkbox" mods={{ row: true }}>
                <FormikCheckbox
                  name="dateFormat"
                  id="timeFormatUTC"
                  type="radio"
                  value="utc"
                  checked={values.dateFormat === 'utc'}
                />
              </FormField>
              <FormField label="LT" name="timeFormatLocal" className="datetime__form-checkbox" mods={{ row: true }}>
                <FormikCheckbox
                  name="dateFormat"
                  id="timeFormatLocal"
                  type="radio"
                  value="local"
                  checked={values.dateFormat === 'local'}
                />
              </FormField>
              {/* // TODO: Get back after hours option when ready */}
              <FormField
                label="LT AH"
                name="timeFormatLocalAfterHours"
                className="datetime__form-checkbox"
                mods={{ row: true }}
              >
                <FormikCheckbox
                  name="dateFormat"
                  id="timeFormatLocalAfterHours"
                  type="radio"
                  value="localAfterHours"
                  checked={values.dateFormat === 'localAfterHours'}
                  // disabled={!isAfterHoursAvailable}
                  disabled
                />
              </FormField>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

DateTimeRangeForm.propTypes = {
  chartName: PropTypes.string.isRequired,
};

export default DateTimeRangeForm;
