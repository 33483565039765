// TODO: fix eslint
/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import AccordionTab from 'components/base/AccordionTab';
import DateTimePicker from 'components/UI/DatePicker';
import { DateTime } from 'luxon';

const timeNow = DateTime.now();

const generateCurrentDate = () => {
  const currentDate = new Date();
  const pastHour = new Date(currentDate.getTime() - 60 * 60 * 1000);

  return {
    from: pastHour.toISOString(),
    to: currentDate.toISOString(),
  };
};

const defaultValue = {
  radius: 30,
  ...generateCurrentDate(),
};

const FlightHistoryData = ({ onChange }) => {
  const [isTabOpened, setTabOpened] = useState(true);

  const timeFrom = timeNow
    .set({
      hour: timeNow.hour - 1,
      minute: 0,
    })
    .toFormat("yyyy-MM-dd'T'HH:mm");

  const timeTo = timeNow.set({ minute: 0 }).toFormat("yyyy-MM-dd'T'HH:mm");

  const [historyData, setHistoryData] = useState(defaultValue);

  const handleHistoryDataChange = (data) => setHistoryData({ ...historyData, ...data });

  const onTabToggle = (value) => setTabOpened(value);

  const handleDateSelect = (date) => handleHistoryDataChange(date);

  useEffect(() => onChange(historyData), [historyData]);

  return (
    <div className="flight-history-tab">
      <AccordionTab label="Time" name="date" opened={isTabOpened} setOpened={onTabToggle}>
        <DateTimePicker
          onChange={handleDateSelect}
          defaultValue={{
            from: timeFrom,
            to: timeTo,
          }}
        />
      </AccordionTab>
    </div>
  );
};

export { FlightHistoryData };
