import React from 'react';
import PropTypes from 'prop-types';

const TableReloadButton = ({ onClick }) => {
  return (
    <button className="container__button" type="button" onClick={onClick}>
      RELOAD
    </button>
  );
};

TableReloadButton.propTypes = {
  onClick: PropTypes.func,
};

export default TableReloadButton;
